.nav_main{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
    column-gap: 40px;
    margin: 10px;
}

.link{
    text-decoration: none;
    color: black;
    padding: 10px;
    background-color: rgb(243, 193, 66);
    margin-top: 10px;
    border-radius: 10px;
    text-align: center;
    color: whitesmoke;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: x-large
};